import { useAuthStore } from '~/stores/auth.store';
import { useGameStore } from '~/stores/games.store';
import { useSiteStore } from '~/stores/site.store';

export default async function (): Promise<Array<number>> {
  const { $gameService } = useServices();
  const auth = useAuthStore();
  const games = useGameStore();

  const page = useGetPage();

  if (auth.loggedIn) {
    await $gameService.fetchGameFavourites({
      channel: 'WebDesktop',
      languageCode: 'en-US',
      currency: 'USD',
      vertical: page.value?.Mapping,
      token: auth.access_token,
    });
  }
  return games.returnFavoriteIds;
}

<script setup lang="ts">
import DesignSystemButton from '../design-system/button.vue';

const siteStore = useSiteStore();
const router = useRouter();
const route = useRoute();
const navigation = computed(
  () => useGetPage('secondary-navigation', false).value.Children || [],
);
const activeNavigation = computed(() => {
  return (index: number) => {
    switch (index) {
      case 0:
        return 'primary';
      case 1:
        return 'neutral';
      case 2:
        return 'primary-alternative';
    }
  };
});

const isActive = computed(() => !!navigation.value);
</script>

<template>
  <div
    v-if="isActive"
    class="bg-primary-layer-alternative p-1 rounded-2xl lg:flex gap-1 hidden"
  >
    <DesignSystemButton
      v-for="(child, index) in navigation"
      :key="index"
      :type="
        route.path === child?.RURL ? activeNavigation(+index) : 'transparent'
      "
      @click="router.push(child?.RURL)"
    >
      {{ child?.C }}
    </DesignSystemButton>
  </div>
</template>

<style scoped lang="scss"></style>

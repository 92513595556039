<script setup lang="ts">
import { useToast } from 'primevue/usetoast';

import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import { useServices } from '../../composables/useServices';
import { brandDomainSuffix, brandIds } from '~/assets/data/config';
import type { ToastMessageOptions } from 'primevue/toast';
const toast = useToast();

const auth = useAuthStore();
const { $walletService } = useServices();
const siteStore = useSiteStore();
const config = useRuntimeConfig();

const bankingFrame = ref<HTMLIFrameElement>();

interface BankingProps {
  type?: string;
}
const props = withDefaults(defineProps<BankingProps>(), {
  type: '',
});

onMounted(() => {
  if (window.onmessage == null) {
    window.onmessage = (event) => {
      attachMessage(event);
    };
  }
});

onBeforeUnmount(() => {
  window.onmessage = null;
});

const url = computed(() =>
  document.location.hostname.includes('localhost')
    ? `https://${config.public.bankingEnvironment}.${
        brandDomainSuffix[siteStore.getRegionCode]
      }/banking-app/${props.type}/?msisdn=${
        auth.currentUser.primaryMobileNumber
      }&from=jpc&locale=en-US&theme=${useColorMode().preference}`
    : `https://${document.location.hostname}/banking-app/${
        props.type
      }/?msisdn=${
        auth.currentUser.primaryMobileNumber
      }&from=jpc&locale=en-US&theme=${useColorMode().preference}`
);

const tempData = computed(() => {
  return {
    UserData: {
      Method: 'setToken',
      Msisdn: auth.currentUser.username,
      BrandId: brandIds[siteStore.getRegionCode],
      Id: auth.userId,
      CommunicationChannel: 0,
      Token: auth.currentAccessToken,
    },
  };
});

const refreshUserBalances = async () => {
  await $walletService.fetchBalances();
};

const isIFrame = computed(
  () => !!bankingFrame.value && bankingFrame.value.tagName === 'IFRAME'
);

function attachMessage(event: MessageEvent) {
  const bankingType = props.type;
  const bankingFrameObject: HTMLIFrameElement = document.querySelector(
    '[id*=banking_frame]'
  );
  let bankingFrameName = '';
  if (bankingFrameObject != null) {
    bankingFrameName = bankingFrameObject.id;
  }

  if (event.data.toString().includes('refreshBalance')) {
    refreshUserBalances();
  }

  if (event.data.toString().includes('openChat')) {
    siteStore.toggleFeature({ feature: 'displayLiveChat' });
  }

  if (event.data.toString().includes('resizeFrame')) {
    const resizeData = JSON.parse(event.data);
    bankingFrameObject.style.height = resizeData.size;
  }

  if (event.data.toString().includes('loadedIframe')) {
    if (isIFrame && !!bankingFrameObject.contentWindow) {
      bankingFrameObject.contentWindow.postMessage(
        'loaded' + bankingType + 'Resize',
        '*'
      );
    }

    if (isIFrame && !!bankingFrameObject.contentWindow) {
      bankingFrameObject.contentWindow.postMessage(
        JSON.stringify(tempData.value),
        '*'
      );
    }
  }
  if (event.data.toString().includes('setAccountData')) {
    if (isIFrame && !!bankingFrameObject.contentWindow) {
      bankingFrameObject.contentWindow.postMessage(
        JSON.stringify(tempData.value),
        '*'
      );
    }
  }

  showDepositSpecific(event.data, bankingFrameName);
  showWithdrawalSpecific(event.data);
}

function showDepositSpecific(data: any, bankingFrameName: string) {
  if (data.type === 'setdepositiframe') {
    let frame = document.getElementById(bankingFrameName);
    if (isIFrame && !!bankingFrameObject.contentWindow) {
      frame.setAttribute('scrolling', 'auto');
    }
  }
  let message;
  if (data.type === 'refreshdepositbalance') {
    message = 'You have successfully deposit money.';
    if (data.alert === 'warning') {
      message = 'You have cancelled your deposit money request.';
    } else if (data.alert === 'error') {
      message = 'Your deposits request have failed';
    }

    showToast({
      summary: 'Deposit Status',
      detail: message,
      severity: data.alert,
    });

    refreshUserBalances();
  }

  if (data.type === 'refreshdepositbalanceMM') {
    refreshUserBalances();
  }
}
function showWithdrawalSpecific(data: any) {
  if (data.type === 'withdrawrefreshbalance') {
    showToast({
      summary: 'Successful Withdrawal',
      detail: 'Your withdraw transaction was succcesful.',
      severity: 'success',
    });

    refreshUserBalances();
  }
}

function showToast(obj: Partial<ToastMessageOptions>) {
  toast.add({ ...obj, life: 3000, group: 'toast' });
}
</script>
<template>
  <iframe
    :id="'banking_frame' + type"
    :key="type"
    ref="bankingFrame"
    :src="url"
    class="w-full max-view-width banking_frame border-0 mt-2"
    marginwidth="0"
    marginheight="0"
    frameborder="0"
    scrolling="yes"
    allowtransparency="true"
  />
</template>

<style lang="scss" scoped>
.banking_frame {
  min-height: 550px;
  padding-bottom: 10px;
}
</style>

import { defineStore } from 'pinia';
import { lockBodyScroll, unlockBodyScroll } from '~/utilities/ui-utilities';
import { useSiteStore } from '~/stores/site.store';
import { useGameStore } from '~/stores/games.store';
import type {
  AuthStoreInterface,
  LogoutOptions,
} from '~/interfaces/stores/auth.store.interface';
import type { AccountModals } from '~/interfaces/dto/general/modals';
import type { IAccountBalances } from '~/interfaces/dto/wallet/response';
import type { IUserInfoExtended } from '~/interfaces/dto/auth/response';
import { useServices } from '~/composables/useServices';
import useGetFavorites from '~/composables/useGetFavorites';
import type { CasinoBonus } from '~/interfaces/dto/bonus/response';
import type { IRestrictionPermissions } from '~/interfaces/dto/player/response';
import { useQueryHooks } from '~/utilities/query-hooks';

const hideBalancesKey = 'show-balances';
export const useAuthStore = defineStore('auth', {
  state: (): AuthStoreInterface => {
    return {
      userId: '',
      currentUser: {},
      isLoggedIn: false,
      currentAccessToken: '',
      currentRefreshToken: '',
      displayAccountModal: false,
      welcomeOffer: false,
      displayBalances: true,
      userBalances: {},
      compliance: true,
      partialPostReg: true,
      accountModalPage: null,
      activeBonus: {},
      passwordResetMobileNumber: null,
      restrictionPermissions: {
        allowedLogin: true,
        allowedPayout: true,
        allowedWager: true,
        allowedWithdrawal: true,
      },
    };
  },
  actions: {
    async initAuth() {
      const { $storageService, $authService } = useServices();
      try {
        const accessToken = $storageService.getCookie({
          key: 'auth:access_token',
        });

        const refreshToken = $storageService.getUniversalStorage({
          key: 'auth:refresh_token',
        });

        if (!!accessToken && !!refreshToken) {
          const userId = $storageService.getUniversalStorage({
            key: 'auth:userId',
          });
          this.setAccessToken(accessToken);
          this.setRefreshToken(refreshToken);
          this.setUserId(userId);
          this.setLoggedIn();
        } else if (!!refreshToken && !accessToken) {
          this.setRefreshToken(refreshToken);
          await $authService.refreshToken();
          this.setLoggedIn();
        } else {
          this.logout();
        }
      } catch (e) {
        console.error(e);
      }
    },
    setUser(data: IUserInfoExtended | Record<string, unknown>) {
      try {
        this.currentUser = data;
        const { $storageService } = useServices();
        $storageService.setUniversalStorage({
          key: 'auth:user',
          data: JSON.stringify(data),
        });
        this.setUserId(data?.id);
      } catch (e) {
        console.error('failed to set user');
      }
    },
    setUserbalances(data: IAccountBalances) {
      this.userBalances = data;
    },
    setBalancesVisibility() {
      const { $storageService } = useServices();
      const visibility: boolean | string = $storageService.getLocalStorage({
        key: hideBalancesKey,
      });
      this.toggleBalances(!!visibility.toString() ? Boolean(visibility) : true);
    },
    toggleBalances(display: boolean) {
      const { $storageService } = useServices();
      this.displayBalances = display;
      $storageService.setLocalStorage({
        key: hideBalancesKey,
        data: Boolean(display),
      });
    },
    async setLoggedIn() {
      const { $promotionService, $walletService, $authService, $toastService } =
        useServices();

      if (!this.currentAccessToken) this.isLoggedIn = false;
      else {
        const getFavorites = useGetFavorites;
        const site = useSiteStore();
        site.deactivateModal();
        if (!import.meta.env.SSR) unlockBodyScroll();
        this.isLoggedIn = true;
        const games = useGameStore();
        games.hydrateRecentlyPlayed(this.userId);
        await getFavorites();
        this.setBalancesVisibility();
        await $walletService.fetchBalances();
        await $authService.getUserExtended();

        const route = useRoute();
        if (!!route.query) useQueryHooks(route);

        await $toastService.toastService.fetchToasts();
        await $promotionService
          .getBigCityLifeWheelSpinCount()
          .then(async (d) => {
            if (d.spinCount === 0)
              await $promotionService.getAigWheelSpinCount();
          })
          .catch((e) => console.error(e));
      }
    },
    setAccessToken(access_token: string | undefined) {
      try {
        this.currentAccessToken = access_token;
        const { $storageService } = useServices();
        $storageService.setCookie({
          key: 'auth:access_token',
          data: access_token,
          expiry: 1,
        });
      } catch (e) {
        console.error('failed to set access token');
      }
    },
    setRefreshToken(refresh_token: string | undefined) {
      try {
        this.currentRefreshToken = refresh_token;
        const { $storageService } = useServices();
        $storageService.setUniversalStorage({
          key: 'auth:refresh_token',
          data: refresh_token,
          expiry: 365,
        });
      } catch (e) {
        console.error('failed to set refresh token');
      }
    },
    setUserId(userId: string | undefined) {
      this.userId = userId;
      const { $storageService } = useServices();
      $storageService.setUniversalStorage({
        key: 'auth:userId',
        data: userId,
      });
      window?.dtrum?.identifyUser(userId);
    },
    setWelcomeOffer(flag: boolean) {
      this.welcomeOffer = flag;
    },
    setPartialPostReg(flag: boolean) {
      this.partialPostReg = flag;
    },
    setDocumentVerificationStatus(flag: boolean) {
      this.compliance = flag;
    },
    logout(
      { redirect, path, reload }: LogoutOptions = {
        redirect: false,
        path: '/',
        reload: false,
      },
    ) {
      const { $storageService } = useServices();
      const removalKeys = [
        'auth:refresh_token',
        'auth:access_token',
        'auth:userId',
        'auth:user',
        'pwdResetNumber',
      ];

      removalKeys.forEach((key) => {
        $storageService.removeUniversalStorage({ key });
      });
      this.$reset();
      this.toggleAccountModal(false);
      const games = useGameStore();
      games.clearRecentlyPlayed();
      games.clearFavourites();
      unlockBodyScroll();

      if (!!window?.navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          for (const registration of registrations) {
            if (
              registration.active.scriptURL.includes('firebase-messaging-sw')
            ) {
              registration.unregister();
            }
          }
        });
      }

      if (redirect) {
        window.location.href = !!path ? path : '/';
        return;
      }
      if (reload) {
        const router = useRouter();
        router.go(0);
        return;
      }
    },
    toggleAccountModal(active: boolean) {
      this.displayAccountModal = active;
      const { $toastService, $promotionService } = useServices();
      if (!active) {
        $toastService.toastService.fetchToasts();
        $promotionService.getBigCityLifeWheelSpinCount().then(async (d) => {
          if (d.spinCount === 0) await $promotionService.getAigWheelSpinCount();
        });
      }
      active ? lockBodyScroll() : unlockBodyScroll();
    },
    setAccountModalPage(page: AccountModals) {
      if (!!page) this.toggleAccountModal(true);
      this.accountModalPage = page;
    },
    setRestrictionPermissions(permissions: IRestrictionPermissions) {
      this.restrictionPermissions = permissions;
    },
    setActiveBonus(bonus: CasinoBonus) {
      this.activeBonus = bonus;
    },
    setPasswordResetMobileNumber(mobileNumber: string) {
      this.passwordResetMobileNumber = mobileNumber;
    },
  },
  getters: {
    loggedIn(state): boolean {
      return state?.isLoggedIn;
    },
    user(state): IUserInfoExtended | Record<string, unknown> {
      return state?.currentUser;
    },
    access_token(state): string {
      return state?.currentAccessToken;
    },
    user_id(state): string {
      return state?.userId;
    },
    getWelcomeOffer(state): boolean {
      return state?.welcomeOffer;
    },
    accountBalances(state): IAccountBalances {
      return state.userBalances;
    },
    displayAccountBalances(state): boolean {
      return state.displayBalances;
    },
    accountModal(state): boolean {
      return state.displayAccountModal;
    },
    getDocumentVerificationStatus(state) {
      return state.compliance;
    },
    getAccountModalPage(state): AccountModals {
      return state.accountModalPage;
    },
    getIdDocumentStatus(state) {
      return state.currentUser.proofOfIdDocumentStatus;
    },
    getBankDocumentStatus(state) {
      return state.currentUser.proofOfBankDocumentStatus;
    },
    getSupportingDocumentStatus(state) {
      return state.currentUser.proofOfResidenceDocumentStatus;
    },
    getComplianceStatus(state) {
      return state.currentUser?.compliance?.complianceStatus;
    },
    getPartialPostReg(state) {
      return state.partialPostReg;
    },
    getRestrictionPermissions(state) {
      return state.restrictionPermissions;
    },
    getActiveBonus(state) {
      return state.activeBonus;
    },
  },
});

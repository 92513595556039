import type {
  StorageKey,
  StorageSetterCookie,
  StorageSetterGeneral,
  StorageSetterIndexDb,
} from '~/interfaces/dto/storage/storage.interface';
import { del, get, keys, setMany, delMany } from 'idb-keyval';
import dayjs from 'dayjs';

export const useStorageService = () => {
  const siteStore = useSiteStore();
  const runtimeConfig = useRuntimeConfig();
  const isJSON = (str: any): boolean => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  const validateStorageAccess = async () => {
    if (import.meta.client && typeof document.hasStorageAccess === 'function')
      return await document?.hasStorageAccess();
    return false;
  };
  const requestStorageAccess = async () => {
    if (
      import.meta.client &&
      typeof document.requestStorageAccess === 'function' &&
      window?.isSecureContext
    )
      return await document?.requestStorageAccess().then(
        () => {},
        () => {
          console.error('Storage Access denied');
        },
      );
  };
  const setCookie = ({ key, data, expiry }: StorageSetterCookie): void => {
    const cookie = useCookie;

    if (typeof data === 'object') data = JSON.stringify(data);
    const date = new Date();
    const expires = !!expiry
      ? {
          expires: new Date(
            date.setTime(date.getTime() + expiry * 24 * 60 * 60 * 1000),
          ),
        }
      : {};
    cookie(key, {
      path: '/',
      ...expires,
    }).value = data;
  };
  const getCookie = ({ key }: StorageKey): string => {
    const cookie = useCookie;
    return cookie(key).value || '';
  };
  const removeCookie = ({ key }: StorageKey): void => {
    setCookie({ key, data: '' });
  };
  const setLocalStorage = ({ key, data }: StorageSetterGeneral): void => {
    if (typeof window !== 'undefined' && !!localStorage) {
      if (typeof data === 'object') data = JSON.stringify(data);
      localStorage.setItem(key, data);
      const event = new StorageEvent('storage', {
        key,
        newValue: data,
      });
      dispatchEvent(event);
    }
  };
  const getLocalStorage = ({
    key,
    enableListener = false,
  }: StorageKey & { enableListener?: boolean }) => {
    try {
      if (typeof window !== 'undefined' && !!localStorage) {
        if (validateStorageAccess()) {
          requestStorageAccess();
        }
        const val = reactive({ data: '' });
        const data = localStorage.getItem(key) || '';
        if (enableListener)
          window.addEventListener('storage', (e) => {
            if (isJSON(localStorage.getItem(key))) {
              val.data = JSON.parse(localStorage.getItem(key));
            } else {
              val.data = localStorage.getItem(key);
            }
            return val.data;
          });
        if (isJSON(data)) {
          val.data = JSON.parse(data);
        } else {
          val.data = data;
        }
        return val.data;
      }
    } catch (e) {
      console.error(e);
    }
  };
  const removeLocalStorage = ({ key }: StorageKey) => {
    localStorage.removeItem(key);
  };
  const setSessionStorage = ({ key, data }: StorageSetterGeneral) => {
    if (typeof window !== 'undefined' && !!sessionStorage) {
      if (typeof data === 'object') data = JSON.stringify(data);
      sessionStorage.setItem(key, data);
    }
  };
  const getSessionStorage = ({ key }: StorageKey) => {
    try {
      if (typeof window !== 'undefined' && !!sessionStorage) {
        if (validateStorageAccess()) {
          requestStorageAccess();
        }
        const val = reactive({ data: '' });
        const data = sessionStorage.getItem(key) || '';
        window.addEventListener('storage', (e) => {
          if (isJSON(sessionStorage.getItem(key))) {
            val.data = JSON.parse(sessionStorage.getItem(key));
          } else {
            val.data = sessionStorage.getItem(key);
          }
          return val.data;
        });
        if (isJSON(data)) {
          val.data = JSON.parse(data);
        } else {
          val.data = data;
        }
        return val.data;
      }
    } catch (e) {
      console.error({ location: 'getSessionStorage', e });
    }
  };
  const removeSessionStorage = ({ key }: StorageKey) => {
    sessionStorage.removeItem(key);
  };
  const setUniversalStorage = ({ key, data, expiry }: StorageSetterCookie) => {
    setCookie({ key, data, expiry });
    setLocalStorage({ key, data });
    setSessionStorage({ key, data });
  };
  const getUniversalStorage = <T>({ key }: StorageKey): T => {
    const cookie = getCookie({ key });
    const localStorage = getLocalStorage({ key });
    const sessionStorage = getSessionStorage({ key });
    return (cookie || localStorage || sessionStorage) as T;
  };
  const removeUniversalStorage = ({ key }: StorageKey) => {
    removeCookie({ key });
    removeLocalStorage({ key });
    removeSessionStorage({ key });
  };
  // indexDB
  const setIndexDb = async ({
    key,
    data,
    expiry = runtimeConfig?.public.dataCacheTime,
    expires = true,
  }: StorageSetterIndexDb) => {
    if (!!window && !!window?.indexedDB) {
      const sets = [[`${key}-${siteStore.getRegionCode}`, data]];
      if (expires)
        sets.push([
          `${key}:expiry-${siteStore.getRegionCode}`,
          dayjs().add(expiry, 'seconds').valueOf(),
        ]);
      else del(`${key}:expiry-${siteStore.getRegionCode}`);
      await setMany(sets);
    }
  };
  const getIndexDb = async ({ key }: StorageKey) => {
    if (!!window && !!window?.indexedDB) {
      const expiry = await get(`${key}:expiry-${siteStore.getRegionCode}`);
      if (dayjs().isAfter(dayjs(expiry))) {
        await removeIndexDb({ key });
        return null;
      }
      return await get(`${key}-${siteStore.getRegionCode}`);
    }
    return null;
  };

  const removeIndexDb = async ({ key }: StorageKey) => {
    await del(`${key}-${siteStore.getRegionCode}`);
    await del(`${key}:expiry-${siteStore.getRegionCode}`);
  };

  const invalidateStaleIndexDb = async () => {
    if (!!window && !!window?.indexedDB) {
      await keys().then((keys: string[]) => {
        const contentKeys = keys.filter((key) => key.includes(':expiry'));
        contentKeys.forEach((key) => {
          getIndexDb({ key });
        });
      });
    }
  };

  const invalidateBuildVersion = async () => {
    if (!!window && !!window?.indexedDB) {
      await keys().then((keys: string[]) => {
        const contentKeys = keys.filter(
          (key: string) =>
            key.includes('vertical') ||
            key.includes('category') ||
            key.includes('game') ||
            key.includes('demoplay-launch'),
        );
        delMany(contentKeys);
      });
    }
  };

  const invalidateFullCache = async () => {
    if (!!window && !!window?.indexedDB) {
      await keys().then((keys: string[]) => {
        delMany(keys);
      });
    }
  };
  return {
    isJSON,
    setCookie,
    getCookie,
    removeCookie,
    setLocalStorage,
    getLocalStorage,
    removeLocalStorage,
    setSessionStorage,
    getSessionStorage,
    removeSessionStorage,
    setUniversalStorage,
    getUniversalStorage,
    removeUniversalStorage,
    setIndexDb,
    getIndexDb,
    removeIndexDb,
    invalidateStaleIndexDb,
    invalidateBuildVersion,
    invalidateFullCache,
    indexDbGet: get,
    indexDbRemove: del,
  };
};

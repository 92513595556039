<script setup>
import Button from '../../components/design-system/button.vue';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import UserBalances from '~/components/user/user-balances.vue';
import GenericThemeToggle from '../user-interface/generic-theme-toggle.vue';
import ExternalIcon from '../design-system/icons/ExternalIcon.vue';
import ChatIcon from '../design-system/icons/ChatIcon.vue';

const { $enabled } = useNuxtApp();

const route = useRoute();
const auth = useAuthStore();
const settings = useSiteStore();
const emit = defineEmits(['themeChanges']);
</script>
<template>
  <div>
    <div class="flex items-center justify-items-end gap-2">
      <div class="hidden md:block">
        <div
          element-name="nav-bar-search"
          class="flex items-center cursor-pointer p-2 rounded-xl bg-base text-base whitespace-nowrap"
          @click="settings.activateModal('search')"
        >
          {{ $t('search-games') }}
          <ExternalIcon
            class="ml-2 w-6 mx-auto"
            icon-name="search-magnifying"
          />
        </div>
      </div>
      <!--    Logged out -->
      <div v-if="!auth.loggedIn" class="account flex items-center h-full gap-2">
        <Button
          element-name="nav-bar-login"
          type="tertiary"
          padding="wide"
          @click="settings.activateModal('login')"
        >
          {{ $t('login') }}
        </Button>
        <Button
          element-name="nav-bar-register"
          group="primary"
          padding="wide"
          @click="settings.activateModal('register')"
        >
          {{ $t('jpc-register') }}
        </Button>
      </div>
      <!--    Logged in-->
      <div v-else class="account flex items-center h-full gap-2">
        <UserBalances
          :display-cash-balance="!route.params?.game"
          display-account-balance-toggle
          display-deposit-button
          deposit-button-style="full"
          deposit-button-fallback="text"
          stretch
        />
        <Button
          element-name="display-notifications"
          v-if="$enabled('website.notificationpanel')"
          class="hidden md:flex relative"
          type="tertiary"
          @click="settings.toggleFeature({ feature: 'displayNotifications' })"
        >
          <ExternalIcon class="w-6 mx-auto" icon-name="notification" />
          <div
            v-if="settings.getUnreadNotificationsCount > 0"
            class="red-badge animate-ping"
          />
          <div class="red-badge text-0.5xs">
            {{ settings.getUnreadNotificationsCount }}
          </div>
        </Button>
        <Button
          class="hidden md:flex relative"
          type="tertiary"
          @click="settings.setHamburgerMenu(true)"
        >
          <ExternalIcon class="w-6 mx-auto" icon-name="account-settings" />
          <div
            v-if="
              !auth.getDocumentVerificationStatus &&
              settings.getFeatureFlags.get('myaccount.enablecomplaince')
            "
            class="red-badge animate-pulse"
          />
        </Button>
      </div>

      <Button
        element-name="live-chat"
        v-if="$enabled('website.livechat')"
        class="hidden md:flex"
        type="tertiary"
        @click="settings.toggleFeature({ feature: 'displayLiveChat' })"
      >
        <ChatIcon />
      </Button>
      <GenericThemeToggle v-if="!auth.loggedIn" />
    </div>
  </div>
</template>

<style scoped>
.red-badge {
  @apply absolute w-[15px] h-[15px] top-[5px] right-[5px] bg-red-500 rounded-full z-5 text-white;
}
</style>

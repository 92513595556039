import { BaseService } from '~/services/base.service';
import { useSiteStore } from '~/stores/site.store';

export const useWinnerService = () => {
  const appConfig = useRuntimeConfig();
  const siteStore = useSiteStore();

  const baseC = new BaseService(appConfig.public.baseC);

  const regionCode = siteStore.getRegionCode;
  const channel = 'WebDesktop';
  const vertical = 'Home';
  const languageCode = 'en';
  const currency = 'USD';

  const fetchAllWinners = async () => {
    try {
      const requestUrl: string = baseC.requestUrl({
        path: `api/v1/Widget/WinnerCircle`,
        queryParams: {
          regionCode: regionCode,
          channel: channel,
          environment: appConfig.public.environment,
        },
      });

      return await $fetch(requestUrl);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchMostPopularGames = async () => {
    try {
      const requestUrl: string = baseC.requestUrl({
        path: `api/v1/Gaming/Game/MostLiked`,
        queryParams: {
          channel: channel,
          languageCode: languageCode,
          currency: currency,
          limit: 10,
        },
      });

      return await $fetch(requestUrl, {
        headers: {
          'X-Region-Code': regionCode,
          'X-Vertical': vertical,
          'X-Environment': appConfig.public.environment,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const fetchMostLikedGames = async () => {
    try {
      const requestUrl: string = baseC.requestUrl({
        path: `api/v1/Gaming/Game/MostLiked`,
        queryParams: {
          channel: channel,
          languageCode: languageCode,
          currency: currency,
          limit: 10,
        },
      });

      return await $fetch(requestUrl, {
        headers: {
          'X-Region-Code': regionCode,
          'X-Vertical': vertical,
          'X-Environment': appConfig.public.environment,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return {
    fetchAllWinners,
    fetchMostPopularGames,
    fetchMostLikedGames,
  };
};

<script setup lang="ts">
import { useAuthStore } from '~/stores/auth.store';
import { useGameStore } from '~/stores/games.store';
import { trackingMethods } from '~~/plugins/analytics.client';
import useLaunchGame from '../../composables/useLaunchGame';
import { useSiteStore } from '~/stores/site.store';
import { HandThumbUpIcon, HeartIcon } from '@heroicons/vue/24/solid';
import { useFormatNumberCompact } from '../../composables/useFormatters';
import LazyImage from '~/components/design-system/lazy-image.vue';
import GenericTag from '../user-interface/generic-tag.vue';
import type { IGameCategory } from '~/interfaces/dto/games/response';
import type { IGame } from '~/interfaces/winners/winnersInterfaces';

const { $enabled } = useNuxtApp();

const auth = useAuthStore();
const site = useSiteStore();
const games = useGameStore();
const launchGameComp = useLaunchGame;
const route = useRoute();

const settings = useSiteStore();

interface CardProps {
  id?: string | number;
  image?: string;
  title?: string;
  subtitle?: string;
  game?: IGameCategory | IGame | null;
  skeleton?: boolean;
  minBetAmount?: string;
  verticalTile?: boolean;
  isCarousel?: boolean;
  displayContent?: boolean;
  displayHoverGlow?: boolean;
}

const props = withDefaults(defineProps<CardProps>(), {
  id: 0,
  image: '',
  title: '',
  subtitle: '',
  game: null,
  skeleton: false,
  minBetAmount: '',
  verticalTile: false,
  isCarousel: false,
  displayContent: true,
  displayHoverGlow: true,
});

const imagePath = useImagePath;
const displayTitle = computed(() => {
  if (props.verticalTile) return false;
  return !site.getFeatureFlags.get('website.hidegametitles');
});
const isFavorite: globalThis.ComputedRef<boolean> = computed(() => {
  return games.returnFavoriteIds.some((f) => f === props.id);
});
const path = computed(() => {
  return site.logo === 'roa'
    ? 'Jackpotcity.mw/Content'
    : 'Jackpotcity.co.za/content-pages';
});
function favoriteClicked(e: Event) {
  e.stopPropagation();
  if (auth.isLoggedIn) {
    isFavorite.value ? removeFavorite(props.game) : addFavorite(props.game);
  } else {
    settings.activateModal('login');
  }
}

function addFavorite(game: any) {
  useAddFavorites({ game });
  // send GA tracking method when game favourited
  trackingMethods.favouriteGameAdd(game.name, auth.currentUser.username);
}

function removeFavorite(game: any) {
  useRemoveFavorites({ game });

  // send GA tracking method when game unfavourited
  trackingMethods.favouriteGameRemove(game.name, auth.currentUser.username);
}

const card = ref();
const cardHolster = ref();

const gameUnavailable = computed(() => {
  if (props.game?.unavailable) return true;
  return games.returnDisabledProviders.includes(props.game?.provider);
});

function launchGame(game: any, freePlay = false) {
  if (gameUnavailable.value) return;
  launchGameComp({
    game,
    freePlay,
    fromProvider: route.path.includes('provider'),
    fromFavorites: route.path.includes('favorites'),
    fromGoldenCircle: route.path.includes('winners'),
  });
  // send GA tracking method when game is launched
  trackingMethods.launchReal(game.name, auth.currentUser.username);
}
</script>

<template>
  <div
    v-if="!skeleton"
    ref="cardHolster"
    :class="{
      'is-carousel': props.isCarousel,
      'w-full': !props.isCarousel,
      unavailable: gameUnavailable,
      'game-card text-base cursor-pointer bg-layer-2 dark:bg-layer-1 rounded-md overflow-hidden flex-shrink-0': true,
      glow: props.displayHoverGlow,
    }"
    @click="
      auth.loggedIn || !game.freeplay || !$enabled('website.enablefreeplay')
        ? launchGame(props.game, false)
        : null
    "
  >
    <div
      class="w-full justify-center relative overflow-hidden"
      :class="props.verticalTile ? 'aspect-auto h-full' : 'aspect-square'"
    >
      <div
        v-if="!skeleton"
        class="absolute hover:cursor-pointer"
        style="z-index: 1; right: 2px; top: 2px; padding: 2px 4px"
        @click="favoriteClicked"
      >
        <HeartIcon
          :class="[
            'w-6',
            {
              'primary-pink-gradient-text': isFavorite,
              'text-base': !isFavorite,
            },
          ]"
        />
      </div>
      <CategoryCardMaintenance v-if="gameUnavailable" />
      <CategoryCardLaunchButtons
        v-if="
          !auth.loggedIn &&
          !gameUnavailable &&
          $enabled('website.enablefreeplay')
        "
        class="launch-buttons hidden transition-all duration-100 ease-in-out"
        @free-play="launchGame(props.game, true)"
        @real-play="launchGame(props.game, false)"
        :has-freeplay="game.freeplay"
      />

      <LazyImage
        :id="props.id"
        ref="image"
        class="w-full h-full overflow-hidden relative object-cover"
        :class="props.verticalTile ? 'aspect-auto' : 'aspect-square'"
        :src="props.image"
        :placeholder="
          imagePath(
            `/medialibraries/${path}/placeholders/JPC_GameTilePlaceHolder_500x500.jpg`,
          )
        "
        :alt="props.title"
        :width="card?.scrollWidth"
        :height="card?.scrollHeight"
      />
      <GenericTag v-if="game?.exclusive" type="exclusive" position="top-left" />
      <GenericTag v-if="game?.isNew" type="new" position="bottom-left" />
      <GenericTag v-if="game?.isPromoted" type="promo" position="bottom-left" />
    </div>
    <div
      v-if="displayTitle && displayContent"
      class="w-full overflow-hidden p-1 text-base-priority text-xs text-left"
    >
      <strong class="w-full line-clamp-1">
        {{ props.title || ' ' }}
      </strong>
      <div class="flex justify-between truncate w-full capitalize">
        <div
          class="minBetAmount"
          :style="{ opacity: !!props?.game?.minBetAmount ? 1 : 0 }"
        >
          {{ props?.game?.minBetAmount || 0 }}
        </div>
        <div v-if="props?.game?.likesCount" class="flex items-center gap-0.5">
          <HandThumbUpIcon class="w-4" />
          {{ useFormatNumberCompact(props?.game?.likesCount) }}
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <UserInterfaceGenericSkeleton
      width-class="w-full"
      height-class="h-full"
      :class="props.verticalTile ? 'aspect-auto' : 'aspect-square'"
      rounded-class="rounded-t-md"
    />
    <UserInterfaceGenericSkeleton
      width-class="w-full"
      height-class="h-10"
      rounded-class="rounded-b-md"
    />
  </div>
</template>

<style scoped>
* {
  user-select: contain;
}

.game-card:hover > * > .launch-buttons {
  display: block;
}

.gold-dot {
  width: 12px;
  height: 12px;
}
.gold-dot::before {
  content: ' ';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-image: linear-gradient(45deg, #d87700 0%, #ffc018);
}

.gold-dot::after {
  content: ' ';
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  opacity: 0.25;
  background-color: #ffc018;
}
.dark .maintenance {
  background-color: #1d2129c9;
}
.maintenance {
  background-color: #ffffffc9;
}

.game-card:hover:not(.unavailable) img {
  transition: box-shadow ease-in-out 300ms;
  box-shadow: 0 8px 8px 4px rgba(56, 121, 251, 0.3);
}

.game-card.unavailable:hover,
.game-card.unavailable {
  cursor: not-allowed;
  pointer-events: none;
}

.game-card:not(:hover),
.game-card.unavailable {
  box-shadow: unset;
}

.header-top-item button {
  padding: 3.5px 5px !important;
  height: 20px !important;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

.likes {
  padding: 2px;
  border-radius: 5px;
  font-size: 0.75rem;
}

.text-content {
  line-height: 12px !important;
  padding: 2px !important;
}

@media screen and (max-width: 1024px) {
  .header-top-item button {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 767px) {
  .likes-bet {
    font-size: 0.7rem !important;
  }
}

::selection {
  background: transparent;
}
::-moz-selection {
  background: transparent;
}
</style>

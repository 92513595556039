<script setup lang="ts">
import Pagination from '~/components/design-system/pagination.vue';
import { useAuthStore } from '~/stores/auth.store';
import GenericError from '~/components/user-interface/generic-error.vue';
import GenericLoader from '~/components/user-interface/generic-loader.vue';
import { formatTransactionDate } from '~/utilities/data-transformers';
import { useServices } from '../../../composables/useServices';
import Button from '~/components/design-system/button.vue';
import { ArrowPathIcon } from '@heroicons/vue/24/outline';
import { trackingMethods } from '~/plugins/analytics.client';
import type { ITransactionRequest } from '~/interfaces/dto/wallet/request';

const currentPage: globalThis.Ref<number> = ref(0);
const totalPages: globalThis.Ref<number> = ref(0);
const recordsPerPage: globalThis.Ref<number> = ref(0);
const showingStart: globalThis.Ref<number> = ref(0);
const showingEnd: globalThis.Ref<number> = ref(0);
const totalResults: globalThis.Ref<number> = ref(0);
const auth = useAuthStore();
const { $walletService, $storageService } = useServices();
const { $t } = useNuxtApp();

const activeLoader: globalThis.Ref<boolean> = ref(true);
let currentTransactions;
const newData = reactive({ value: [] });
const errorMessage = ref('sorry-cant-find-results');
const showDetailTransaction = ref(false);
const transactionId = ref('');

interface ITableData {
  transactionId: number;
  date: string;
  gameName: string;
  transactionType: string;
  amount: number;
  isAssignBonus?: boolean;
}

const headers = ref([
  {
    id: 'transactionId',
    currency: false,
  },
  {
    id: 'date',
    currency: false,
  },
  {
    id: 'gameName',
    currency: false,
  },
  {
    id: 'transactionType',
    currency: false,
  },
  {
    id: 'amount',
    currency: true,
  },
]);

const mobileHeaders = ref([
  {
    id: 'date',
    currency: false,
  },
  {
    id: 'gameName',
    currency: false,
  },
  {
    id: 'amount',
    currency: true,
  },
]);

function readData() {
  currentPage.value = 1;
  recordsPerPage.value = 20;
  showingStart.value = 1;
  showingEnd.value = recordsPerPage.value;
}

const d = new Date();
const data: ITransactionRequest = {
  accountId:
    auth.user_id ||
    $storageService.getUniversalStorage({
      key: 'auth:userId',
    }),
  dateFrom: new Date(d.setDate(d.getDate() - 90)),
  pageIndex: 1,
  pageSize: 20,
};

async function getTransactions(pageIndex: number) {
  newData.value = [];
  activeLoader.value = true;
  data.pageIndex = pageIndex;
  try {
    await $walletService.fetchTransactions(data).then(
      ({ data }) => {
        totalResults.value = data.totalRecords;
        totalPages.value = data.totalPages;
        currentPage.value = data.pageIndex;
        activeLoader.value = false;
        data.transactionLogs.forEach((record) => {
          const flagAssignBonus =
            record.transactionTypeId === '00000000-0000-0000-da7a-000000600141';
          const tableRow: ITableData = {
            transactionId: record.sequenceId,
            date: formatTransactionDate(record.createdDateTime),
            gameName: flagAssignBonus
              ? $t('jpc-assign-bonus')
              : record.note || '-',
            transactionType: record.transactionTypeDescription || '-',
            amount: record.isCredit ? record.amount : -1 * record.amount,
            isAssignBonus: flagAssignBonus,
          };
          newData.value.push(tableRow);
        });
        currentTransactions = data.transactionLogs;
      },
      (err) => {
        activeLoader.value = false;
        errorMessage.value = 'an-error-occurred';
        console.error(err);
      },
    );
  } catch (e) {
    activeLoader.value = false;
    errorMessage.value = 'an-error-occurred';
  }
}

function next() {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
    getTransactions(currentPage.value);
  }
}

function select(pageNo: number) {
  currentPage.value = pageNo;
  getTransactions(currentPage.value);
}

function previous() {
  if (currentPage.value > 1) {
    currentPage.value--;
    getTransactions(currentPage.value);
  }
}

function updatePage(start: number, end: number, total?: number) {
  showingStart.value = start;
  showingEnd.value = end;
  totalPages.value = total ?? totalPages.value; //if total exists set totalPages to total
}

async function showDetail(value) {
  transactionId.value = currentTransactions.find(
    (t) => t.sequenceId === value,
  ).financialReference;
  toggleShowDetail();
}

function toggleShowDetail() {
  showDetailTransaction.value = !showDetailTransaction.value;
}

onBeforeMount(async () => {
  readData();
  await getTransactions(data.pageIndex);
  trackingMethods.myAccountHistory();
});

async function refreshTransactions() {
  await getTransactions(currentPage.value);
  trackingMethods.myAccountHistoryTransactionRefresh();
}

const display = computed<boolean>(() => activeLoader.value);
</script>

<template>
  <div v-if="!showDetailTransaction">
    <LazyUserAccountSettingsHeader
      :title="$t('transaction-summary')"
      return-route="history"
      class="pt-1 sm:mb-4"
    >
      <template #refresh>
        <Button
          element-name="transaction-summary-refresh-transaction"
          v-if="!display"
          type="secondary"
          rounding="md"
          class="dark:!bg-dark-900 sm:dark:!bg-dark-700"
          @click="refreshTransactions"
        >
          <ArrowPathIcon class="!w-5 !h-5" />
        </Button>
      </template>
    </LazyUserAccountSettingsHeader>
    <GenericLoader v-if="display" :standalone="true" />
    <div v-else class="sm:pt-0 sm:p-4 sm:overflow-x-hidden">
      <!-- 🧹🧹 -->
      <LazyDesignSystemTableV2
        v-if="newData.value.length"
        :originalData="newData.value"
        :headers="headers"
        :mobileHeaders="mobileHeaders"
        :clickable="true"
        :totalPages="totalPages"
        :currentPage="currentPage"
        :recordsPerPage="recordsPerPage"
        :total-results="totalResults"
        :search-on-header="`Game Name`"
        :mobile-b-p="`lg`"
        @update-page="updatePage"
        @row-click="showDetail"
      >
        <template #pagination>
          <div
            class="pagination flex place-content-center justify-center py-3 sticky bottom-0 bg-light-200 dark:bg-dark-800 rounded-b-lg"
          >
            <Pagination
              @next="next"
              @previous="previous"
              @select="select"
              :currentPage="currentPage"
              :totalPages="totalPages"
              :showing-start="showingStart"
              :showing-end="showingEnd"
              :total-results="totalResults"
            />
          </div>
        </template>
      </LazyDesignSystemTableV2>
      <GenericError v-else state="danger" class="h-fit my-3">
        {{ $t(errorMessage) }}
      </GenericError>
    </div>
  </div>
  <div v-if="showDetailTransaction">
    <AccountHistoryDetailedTransaction
      @back="toggleShowDetail()"
      title="transaction-summary"
      :transaction-id="transactionId"
    />
  </div>
</template>

<style scoped lang="scss">
th,
td {
  text-align: left;
  padding: 4px 8px;
}
tr:nth-child(even) {
  background-color: #eceff6;
}
tr:nth-child(odd) {
  background-color: #fff;
}

.dark {
  tr:nth-child(even) {
    background-color: #2b303b;
  }
  tr:nth-child(odd) {
    background-color: #1d2129;
  }
}
tbody {
  display: inline-table;
  width: 100%;
}

.pagination {
  box-shadow: -1px -6px 7px -3px rgba(0, 0, 0, 0.19);
  .dark & {
    box-shadow: -5px -6px 12px -1px #000;
  }
}
</style>

<style lang="scss">
.p-dialog-header {
  border: 1px solid #dae0ed;
}

.dark {
  .p-dialog-header {
    border-color: #464f60;
  }
}
</style>
